import CTAButton from '@/components/common/design-system/CTAButton'
import Icon from '@/components/common/Icon'
import Toast from '@/components/common/design-system/Toast'
import MainBody from '@/components/layouts/MainBody'
import { IconNamesEnum } from '@/constants/iconNames.enum'
import { QueryKeys, Routes } from '@/constants/routes'
import AuthContainer from '@/containers/app/AuthContainer'
import useToast from '@/hooks/common/useToast'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { LocalStorage, LocalStorageKeyEnum } from '@/utils/localStorage'
import { SiteSlugEnum } from '@/constants/siteSlug.enum'
import SiteUtils from '@/utils/site'
import { SessionStorage, SessionStorageKeyEnum } from '@/utils/sessionStorage'

const PageLoginContent = () => {
  const toastControls = useToast()
  const { setIsLogin, signInKakao, isLogin } = AuthContainer.useContainer()
  const [site, setSite] = useState<string | null>()
  const { query, replace } = useRouter()

  useEffect(() => {
    if (query?.[QueryKeys.LogoutFlag]) {
      toastControls.addToast('로그아웃 되었어요.')
      delete query[QueryKeys.LogoutFlag]
      replace({ pathname: Routes.Login, query })
      return
    }
    if (query?.[QueryKeys.RefreshExpired]) {
      setIsLogin(false)
      delete query[QueryKeys.RefreshExpired]
      toastControls.addToast('로그인 인증 기간이 만료되었습니다. 재 로그인 해주세요.')
      replace({ pathname: Routes.Login, query })
      return
    }
  }, [query])

  useEffect(() => {
    setSite(LocalStorage.getItem<string>(LocalStorageKeyEnum.SiteSlug))
  }, [])

  useEffect(() => {
    if (isLogin === true) {
      const redirectUrl = SessionStorage.getItem<string>(SessionStorageKeyEnum.BeforeRedirectUrl) || Routes.Home
      replace(redirectUrl)
    }
  }, [isLogin])

  const isDeliPickMe = !!site && SiteUtils.isDeliPickMe(site)
  const handleSignKakaoButtonClick = () => {
    if (query?.[QueryKeys.Redirect]) {
      SessionStorage.setItem<string>(SessionStorageKeyEnum.BeforeRedirectUrl, query[QueryKeys.Redirect] as string)
    }
    signInKakao()
  }

  return (
    <>
      {site === SiteSlugEnum.KonkukSeoul ? (
        <video
          key={SiteSlugEnum.KonkukSeoul}
          muted
          autoPlay
          playsInline
          loop
          className="fixed h-screen w-body object-cover">
          <source src={'/video/login_konkuk.mp4'} type="video/mp4" />
        </video>
      ) : (
        <video key="normal" muted autoPlay playsInline loop className="fixed h-screen w-body object-cover">
          <source src={'/video/login.mp4'} type="video/mp4" />
        </video>
      )}
      {site === SiteSlugEnum.Secsuwon && (
        <div className="relative flex h-fit w-full justify-center pt-[calc(env(safe-area-inset-top)+5rem)]">
          <img className="h-28 w-185 object-contain" src="/images/samsung-login-logo.png" alt="samsung-login-logo" />
        </div>
      )}
      {isDeliPickMe && (
        <div className="absolute top-[11%] flex w-full max-w-body flex-col justify-center">
          <div className="flex w-full items-center justify-center">
            <img className="h-50" src="/images/login-top-info-raemian-leadersone.png" alt="raemian leadersone info" />
          </div>
        </div>
      )}
      <div className="absolute flex h-[80vh] w-full max-w-body flex-col justify-center">
        <div className="title1 text-center font-bold text-white">
          <p>배달비 걱정 없는</p>
          <p>뉴비 오더로 주문하세요</p>
        </div>
      </div>
      <CTAButton divider={false} background="bg-transparent">
        <button
          onClick={handleSignKakaoButtonClick}
          className="flex h-57 w-full items-center justify-center rounded-xs bg-kakao">
          <Icon name={IconNamesEnum.Kakao} />
          <p className="body1 ml-10 font-bold">카카오 로그인</p>
        </button>
      </CTAButton>
      <Toast toastControls={toastControls} className="bottom-120" />
    </>
  )
}

const PageLogin = () => {
  return (
    <MainBody>
      <PageLoginContent />
    </MainBody>
  )
}

export default PageLogin
